import { Injectable, inject } from '@angular/core';
import {
  HttpRequest,
  HttpEvent,
  HttpXsrfTokenExtractor,
  HttpHandlerFn,
  HttpInterceptorFn,
} from '@angular/common/http';
import { Observable } from 'rxjs';

export const XsrfInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    
    const tokenExtractor = inject(HttpXsrfTokenExtractor);

    if (req.method === 'GET' || req.method === 'HEAD') {
        return next(req);
    }

    let csrfToken = tokenExtractor.getToken() as string;
    if (csrfToken !== null && !req.headers.has('X-XSRF-TOKEN')) {
        req = req.clone({ headers: req.headers.set('X-XSRF-TOKEN', csrfToken) });
    }
    
    return next(req);
}
