import {
    HttpRequest,
    HttpEvent,
    HttpInterceptorFn,
    HttpHandlerFn
} from '@angular/common/http';
import { Observable } from 'rxjs';


export const ContentTypeInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {

    req = req.clone({
        headers: req.headers.set('Accept', 'application/json'),
        withCredentials: true
    });

    if (!req.url.endsWith('/upload')) {
        req = req.clone({
            headers: req.headers.set('Content-Type', 'application/json')
        });
    }

    return next(req);
}
