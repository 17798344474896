import { InjectionToken } from '@angular/core';

// It's an abstract class on purpose, as interfaces cannot be used as tokens for DI.
export abstract class AppInfo {
    abstract srvUrl: string;
    abstract appVersion: string|null;
    abstract logoPath: string;
    abstract company: string;
}

export const APP_INFO = new InjectionToken<AppInfo>('app.info');