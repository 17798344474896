import { Routes } from '@angular/router';
import { AUTH_ROUTER_TOKENS } from './router-tokens';

export const auth_routes: Routes = [
    {
        path: AUTH_ROUTER_TOKENS.LOGIN,
        loadComponent: () => import('../login/login.component').then(c => c.LoginComponent),
    },
    {
        path: AUTH_ROUTER_TOKENS.LOGIN2FA,
        loadComponent: () => import('../login2fa/login2fa.component').then(c => c.Login2faComponent),
    },
    {
        path: AUTH_ROUTER_TOKENS.REGISTER,
        loadComponent: () => import('../register/register.component').then(c => c.RegisterComponent),        
    },
    {
        path: AUTH_ROUTER_TOKENS.FORGOT_PWD,
        loadComponent: () => import('../forgot-pwd/forgot-pwd.component').then(c => c.ForgotPwdComponent),
    },
    {
        path: `${AUTH_ROUTER_TOKENS.RESET_PWD}/:token`,
        loadComponent: () => import('../reset-pwd/reset-pwd.component').then(c => c.ResetPwdComponent),
    },
];
