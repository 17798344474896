import { ApplicationConfig, LOCALE_ID } from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { UnAuthenticatedInterceptor, XsrfInterceptor, provideAuthConfig, provideProfileConfig } from '@webapp-wkspace/auth';
import { SideMenuCmdsService } from '@webapp-wkspace/shell';

// By default, Angular only contains locale data for en-US. If you set the value of LOCALE_ID
// to another locale, you must import locale data for that new locale.
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
// import { environment } from 'libs/app-gp/common/src/environments/environment';
import { environment } from "@webapp-wkspace/app-gp-common";
import { provideHttpClient, withInterceptors, withNoXsrfProtection, withXsrfConfiguration } from '@angular/common/http';
import { GPSideMenuCmdsService } from './SideMenuCmds/side-menu-cmds.service';
import { ContentTypeInterceptor, provideAppInfo } from '@webapp-wkspace/common';
registerLocaleData(localeIt, 'it-IT');

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(routes), 
        provideAnimationsAsync(),
        { provide: SideMenuCmdsService, useClass: GPSideMenuCmdsService },
        provideAppInfo({
            srvUrl: environment.srvUrl,
            appVersion: '0.0.1',
            logoPath: 'assets/images/logo.png',
            company: 'Golfo Paradiso Srl',
        }),
        provideAuthConfig({
            user_can_register: false,
        }),
        provideHttpClient(
            
            withNoXsrfProtection(),             // use XsrfInterceptor below
            
            withInterceptors([
                UnAuthenticatedInterceptor,     // if (error.status === 401) ==> router.navigateByUrl('/login');
                ContentTypeInterceptor,         // req.headers.set('Accept', 'application/json'), withCredentials: true
                XsrfInterceptor,                // req.clone({ headers: req.headers.set('X-XSRF-TOKEN', csrfToken) });
            ]),
        ),
        { provide: LOCALE_ID, useValue: 'it-IT' },
        provideProfileConfig({
            cf: true,
            date_of_birth: true,
            birth_place: true,
            place_of_residence: true,
            phone: true,
        }),
    ],
};
