import { IUserActivityInfo } from "./user-activity-info";
import { IPermission, IRole } from "./user-permissions";
import { IUserInfo } from "./user-info";


export interface IUser extends IUserActivityInfo {
    id: number;
    email: string;
    email_verified_at: string | null;   
    two_factor: boolean;
    two_factor_confirmed_at: string | null;  
    
    permissions?: IPermission[];
    roles?: IRole[];
    info?: IUserInfo;
}
export const EMPTY_USER : IUser = {
    id: 0,
    email: '',
    email_verified_at: null,
    two_factor: false,
    two_factor_confirmed_at: null,
};



