import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'lib-error404',
    standalone: true,
    imports: [CommonModule, RouterLink, MatButtonModule],
    templateUrl: './error404.component.html',
    styles: [
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class Error404Component {

}
