import { Injectable, inject } from '@angular/core';
import { Observable, catchError, concatMap, map, tap, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { APP_INFO, HttpErrorService, IUser, LoggerService } from '@webapp-wkspace/common';
import { AUTH_CONFIG } from '../provider/auth-config';
import { ILoginResponse, IQRCodeResponse, IRegistration, IResetPassword, IUpdatePassword } from '../model/auth-model';

@Injectable({
    providedIn: 'root'
})
export class HttpAuthService {    
    private readonly me = 'HttpAuthService';

    private readonly app_info = inject(APP_INFO);
    private readonly auth_cfg = inject(AUTH_CONFIG);
    readonly http = inject(HttpClient);    
    readonly logger = inject(LoggerService);
    readonly httpErrorService = inject(HttpErrorService);


    csrf_cookie(dbgMethodname: string): Observable<any> {
        this.logger.log(dbgMethodname + ' - csrf-cookie ...');    
        return this.http.get(`${this.app_info.srvUrl}sanctum/csrf-cookie`)
            .pipe(
                catchError(err => this.httpErrorService.handleError(err, dbgMethodname, 'CSRF error') ),
                tap(() => {
                    this.logger.log(dbgMethodname + ' - csrf-cookie completed.');                
                }),            
            );
    }

    login(email: string, password: string): Observable<ILoginResponse> {
        const dbgMethodname = `${this.me}::login()`;
        
        return this.csrf_cookie(dbgMethodname).pipe(
            concatMap(() => {
                this.logger.log(dbgMethodname + ` - login ...`);
                return this.http.post<ILoginResponse>(`${this.app_info.srvUrl}api/v1/login`, {
                        email: email.trim(),
                        password
                    })
                    .pipe(
                        catchError(err => this.httpErrorService.handleError(err, dbgMethodname, 'Login error') ),
                        tap((two_factor_info: ILoginResponse) => {
                            this.logger.log(dbgMethodname + ' - login completed.');
                        }),
                    );
            }),
        ); 
    }
    
    logout() {
        const dbgMethodname = `${this.me}::logout()`;        
        this.logger.log(dbgMethodname + ' - logout ...');    
        return this.http.post( `${this.app_info.srvUrl}api/v1/logout`, {})
            .pipe(
                catchError( err => this.httpErrorService.handleError(err, dbgMethodname, 'Error logging out') )
            );
    }

    register(data: IRegistration): Observable<IUser> {
        const dbgMethodname = `${this.me}::register()`;
        return this.csrf_cookie(dbgMethodname).pipe(
            concatMap( () => {
                this.logger.log(dbgMethodname + ` - register ...`);
                const register_url = `${this.app_info.srvUrl}api/v1/register`;
                return this.http.post<IUser>(register_url, data)
                    .pipe(
                        catchError(err => this.httpErrorService.handleError(err, dbgMethodname, 'Register error') ),
                        tap( (srvUserInfo: IUser) => {
                            this.logger.log(dbgMethodname + ' - register completed.');
                        }),
                    );
            }),
        );
    }

    
    getLoggedUser(): Observable<IUser> {
        const dbgMethodname = `${this.me}::getLoggedUser()`;
        this.logger.log(`${dbgMethodname} ...`);
        const path = `${this.app_info.srvUrl}api/v1/user/whoiam`;
        return this.http.get<IUser>(path)
            .pipe(
                catchError( err => {
                    return this.httpErrorService.handleError(err, dbgMethodname, 'Error retrieving logged user');
                }),
                tap( (userInfo: IUser) => {
                    this.logger.log(`${dbgMethodname} - completed`);
                })
            );
    }
    

    forgotPassword(email: string) {
        const dbgMethodname = `${this.me}::forgotPassword()`;
        
        return this.csrf_cookie(dbgMethodname).pipe(
            concatMap( () => {
                this.logger.log(`${dbgMethodname} - forgot-password ...`);
                const path = `${this.app_info.srvUrl}api/v1/forgot-password`;
                return this.http.post<any>(path, {email})
                    .pipe(
                        catchError(err => this.httpErrorService.handleError(err, dbgMethodname, 'Forgot password error') ),
                        tap( () => this.logger.log(`${dbgMethodname} - completed`))
                    );
            }),
        );  
    }

    resetPassword(data: IResetPassword) {
        const dbgMethodname = `${this.me}::resetPassword()`;
        
        return this.csrf_cookie(dbgMethodname).pipe(
            concatMap( () => {
                this.logger.log(`${dbgMethodname} - reset-password ...`);
                const path = `${this.app_info.srvUrl}api/v1/reset-password`;
                return this.http.post<any>(path, data)
                    .pipe(
                        catchError(err => this.httpErrorService.handleError(err, dbgMethodname, 'Reset password error') ),
                        tap( () => this.logger.log(`${dbgMethodname} - completed`))
                    );
            }),
        );  
    }


    sendNewActivationLink() {
        const dbgMethodname = `${this.me}::sendNewActivationLink()`;
        this.logger.log(dbgMethodname + ` ...`);
        const url = `${this.app_info.srvUrl}api/v1/email/verification-notification`;
        return this.http.post(url, null)
            .pipe(                
                catchError(err => this.httpErrorService.handleError(err, dbgMethodname, 'Send New Activation Link error') ),
                tap( () => this.logger.log(`${dbgMethodname} - completed`))
            );
    }

   
    twoFactorChallenge(code: string, isVerificationCode: boolean) {
        const dbgMethodname = `${this.me}::twoFactorChallenge()`;
        this.logger.log(`${dbgMethodname} ...`);
        const path = `${this.app_info.srvUrl}api/v1/two-factor-challenge`;
        
        const data = isVerificationCode ? {recovery_code: code} : {code: code};
        return this.http.post<any>(path, data)
            .pipe(
                catchError(err => this.httpErrorService.handleError(err, dbgMethodname, 'Errore nella verifica dell\'autenticazione a 2 fattori.') ),
                tap( () => this.logger.log(`${dbgMethodname} - completed`) )
            );
    }

    updateEmail(email: string) {
        const dbgMethodname = `${this.me}::updateEmail()`;
        this.logger.log(`${dbgMethodname} ...`);
        const url = `${this.app_info.srvUrl}api/v1/user/profile-information`;
        
        return this.http.put<any>(url, { email })
            .pipe(
                catchError(err => this.httpErrorService.handleError(err, dbgMethodname, 'Update user name or email') ),
                tap( () => this.logger.log(`${dbgMethodname} - completed`))
            );
    }

    updatePassword(pwd: IUpdatePassword) {
        const dbgMethodname = `${this.me}::updatePassword()`;
        this.logger.log(`${dbgMethodname} ...`);
        const path = `${this.app_info.srvUrl}api/v1/user/password`;
        
        return this.http.put<any>(path, pwd)
            .pipe(
                catchError(err => this.httpErrorService.handleError(err, dbgMethodname, 'Update password') ),
                tap( () => this.logger.log(`${dbgMethodname} - completed`))
            );
    }

    /*
        While building your application, you may occasionally have actions that should 
        require the user to confirm their password before the action is performed. 
        Typically, these routes are protected by Laravel's built-in password.confirm middleware.
    */
    confirmPassword(password: string) {
        const dbgMethodname = `${this.me}::confirmPassword()`;
        this.logger.log(`${dbgMethodname} ...`);
        const path = `${this.app_info.srvUrl}api/v1/user/confirm-password`;
        
        return this.http.post<any>(path, {password})
            .pipe(
                catchError(err => this.httpErrorService.handleError(err, dbgMethodname, 'Confirm password error') ),
                tap( () => this.logger.log(`${dbgMethodname} - completed`)),
            );
    }



    getConfirmPasswordStatus() {
        const dbgMethodname = `${this.me}::getConfirmPasswordStatus()`;
        this.logger.log(`${dbgMethodname} ...`);
        const path = `${this.app_info.srvUrl}api/v1/user/confirmed-password-status`;
        
        return this.http.get<any>(path)
            .pipe(
                catchError(err => this.httpErrorService.handleError(err, dbgMethodname, 'Confirm password status error') ),
                tap( () => this.logger.log(`${dbgMethodname} - completed`)),
            );
    }

    /*
    Quando abilito twoFA, l'utente deve mettere una OTP generata da any TOTP compatible mobile authentication application such as Google Authenticator.
    Come funza...
    User deve estendere "User extends Authenticatable" e use Notifiable, TwoFactorAuthenticatable;
    Ci vuole pagina per enable e disable twoFA
    più una pagina per recuperare/regenerate i recovery codes (These recovery codes allow the user to authenticate if they lose access to their mobile device.)
    GET /user/two-factor-recovery-codes     per prenderli
    POST /user/two-factor-recovery-codes    per rigenerarli

    ogni modifica delle impostazioni richiede la conferma della password quindi "should implement Fortify's password confirmation feature"
    Disable:    DELETE  /user/two-factor-authentication
    Enable:     POST    /user/two-factor-authentication --> 200 OK
    la status session variable will be set to two-factor-authentication-enabled 
    si mostra qr code (GET /user/two-factor-qr-code), si aggiunge a google authenticator e 
    ora però the user must still "confirm" their two factor authentication configuration by providing a valid two factor authentication code
    probabilmente per evitare di abilitare una roba che poi non funziona quindi prima si fa una prova, poi lo abilita definitivamente
    quindi POST /user/confirmed-two-factor-authentication se successo allora status session variable will be set to two-factor-authentication-confirmed

    la verifica del otp o recovery code è via POST /two-factor-challenge che torna 204 | 422
    */
    enable2FA() {
        const dbgMethodname = `${this.me}::enable2FA()`;
        this.logger.log(`${dbgMethodname} ...`);
        const path = `${this.app_info.srvUrl}api/v1/user/two-factor-authentication`;
        
        return this.http.post<any>(path, {})
            .pipe(
                catchError((err) => {
                    if(err.status === 423) {
                        return throwError(() => `${dbgMethodname} - need password confirmation`);
                    }
                    return this.httpErrorService.handleError(err, dbgMethodname, 'Errore nell\'abilitare l\'autenticazione a 2 fattori.');
                }),
                tap( () => this.logger.log(`${dbgMethodname} - completed`)),
            );
    }

    disable2FA() {
        const dbgMethodname = `${this.me}::disable2FA()`;
        this.logger.log(`${dbgMethodname} ...`);
        const path = `${this.app_info.srvUrl}api/v1/user/two-factor-authentication`;
        
        return this.http.delete<any>(path)
            .pipe(
                catchError(err => this.httpErrorService.handleError(err, dbgMethodname, 'Errore nell\'abilitare l\'autenticazione a 2 fattori.') ),
                tap( () => this.logger.log(`${dbgMethodname} - completed`) )
            );
    }

    getQrCode(): Observable<string> {
        const dbgMethodname = `${this.me}::getQrCode()`;
        this.logger.log(`${dbgMethodname} ...`);

        return this.http.get<IQRCodeResponse>(`${this.app_info.srvUrl}api/v1/user/two-factor-qr-code`)
            .pipe(
                catchError(err => this.httpErrorService.handleError(err, dbgMethodname, 'Qr code error') ),
                tap( () => this.logger.log(dbgMethodname + ' - completed.')),
                map( (qrCode: IQRCodeResponse) => qrCode.svg),
            );
    }

    getRecoveryCodes(): Observable<string[]> {
        const dbgMethodname = `${this.me}::getRecoveryCodes()`;
        this.logger.log(`${dbgMethodname} ...`);

        return this.http.get<string[]>(`${this.app_info.srvUrl}api/v1/user/two-factor-recovery-codes`)
            .pipe(
                catchError(err => this.httpErrorService.handleError(err, dbgMethodname, 'Recovery codes error') ),
                tap( () => this.logger.log(dbgMethodname + ' - completed.')),                
            );
    }

    confirmedTwoFactorAuthentication(authentication_code: string): Observable<any> {
        const dbgMethodname = `${this.me}::confirmedTwoFactorAuthentication()`;
        this.logger.log(`${dbgMethodname} ...`);
        const path = `${this.app_info.srvUrl}api/v1/user/confirmed-two-factor-authentication`;
        
        return this.http.post<any>(path, {code: authentication_code})
            .pipe(
                catchError(err => this.httpErrorService.handleError(err, dbgMethodname, 'Errore nella verifica della conferma di abilitazione dell\'autenticazione a 2 fattori.') ),
                tap( () => this.logger.log(`${dbgMethodname} - completed`) )
            );
    }
}
