import { Injectable } from '@angular/core';
// import { environment } from '../../../environments/environment.development';

@Injectable({
    providedIn: 'root'
})
export class LoggerService {
    devMode: boolean;

    constructor() { 
        this.devMode = true; // environment.enableLog;
    }

    log(message: string): void {
        if (this.devMode) {
            console.log(this.getTs() + message);
        }        
    }
    warning(msg: string): void {
        console.warn(`${this.getTs()}[WRN] ${msg}`);
    }
    error(msg: string): void {
        console.error(`${this.getTs()}[ERR] ${msg}`);
    }

    private getTs(): string {
        const ts = new Date();
        const dt = ts.toLocaleTimeString();
        const msec = ('00' + ts.getMilliseconds()).slice(-3);
        return `[${dt}.${msec}] `;
    }
}
