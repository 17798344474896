import { HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { LoggerService } from "./logger.service";
import { Injectable, inject } from "@angular/core";
import { HttpError } from "../models/http-error";

@Injectable({
    providedIn: 'root',
})
export class HttpErrorService {

    logger: LoggerService = inject(LoggerService);

    handleError(error: any, serviceName: string = 'service ?', serviceMsg: string = '?'): Observable<never>
    {
        const http_error: HttpError = {
            status_code: undefined,
            error_message: '',
        };

        // Just logging ..
        if (error instanceof HttpErrorResponse) {

            // Deborah
            let errorMessage = '';
            if (error.error instanceof ErrorEvent) {
                // A client-side or network error occurred. Handle it accordingly.
                http_error.error_message = `An error occurred: ${error.error.message}`;
            } else {
                // The backend returned an unsuccessful response code.
                http_error.status_code = error.status;
                http_error.error_message = `Server returned code: ${error.status}, error message is: ${error.statusText}`;
            }
            
            // Mio
            if (error.error?.message) {
                this.logger.error(`${serviceName} - ${error.error.message} (${error.status} ${error.statusText})`);
            }
            if (error.error?.errors) {
                const keys = Object.keys(error.error.errors);
                for (const key of keys) {
                    const keyErrors = error.error.errors[key];
                    for (const keyError of keyErrors) {
                        this.logger.error(`${serviceName} - ${key}: ${keyError}`);
                    }
                }
            }
        } else {
            this.logger.error(`${serviceName} - ${serviceMsg}`);
        }

        
        return throwError(() => error);
    }

}

