import { EnvironmentProviders, makeEnvironmentProviders } from "@angular/core";
import { AUTH_CONFIG, AuthConfig } from "./auth-config";
import { PROFILE_CONFIG, ProfileConfig } from "./profile-config";

export function provideAuthConfig(config: AuthConfig): EnvironmentProviders {

    return makeEnvironmentProviders([
        {
            provide: AUTH_CONFIG,       // user_can_register ?
            useValue: config,
        },
    ]);
}

export function provideProfileConfig(config: ProfileConfig): EnvironmentProviders {

    return makeEnvironmentProviders([
        {
            provide: PROFILE_CONFIG,    // cf ? , ...
            useValue: config,
        },
    ]);
}