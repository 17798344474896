import { EnvironmentProviders, makeEnvironmentProviders } from "@angular/core";
import { APP_INFO, AppInfo } from "./app-info";


export function provideAppInfo(info: AppInfo): EnvironmentProviders {

    return makeEnvironmentProviders([
        {
            provide: APP_INFO,
            useValue: info,
        },
    ]);
}