import { inject, Injectable, signal } from "@angular/core";
import { AuthService } from "@webapp-wkspace/auth";
import { ISideMenuCommand, SideMenuCmdsService } from "@webapp-wkspace/shell";
import { NOTE_EQ_ROUTER_TOKENS } from "libs/app-gp/note-equipaggio/src/lib/routes/feature.router-tokens";
import { ACCOUNT_ROUTER_TOKENS } from "libs/shared/auth/src/lib/account/routes/router-tokens";
import { AUTH_ROUTER_TOKENS } from "libs/shared/auth/src/lib/auth/routes/router-tokens";
import { IMPORT_ROUTER_TOKENS } from "../feature-import/feature.router-tokens";
import { STATINI_ROUTER_TOKENS } from "libs/app-gp/statini/src/lib/routes/feature.router-tokens";
import { TMT_TICKETS_ROUTER_TOKENS } from "@webapp-wkspace/tmt-tickets";

@Injectable({
    providedIn: 'root'
})
export class GPSideMenuCmdsService implements SideMenuCmdsService {
    private readonly authService = inject(AuthService);
    cmds = signal<ISideMenuCommand[]>([]);

    constructor() {

        const cmds_array: ISideMenuCommand[] = [
            {
                icon: 'groups',
                label: 'Note Equipaggio',
                route: NOTE_EQ_ROUTER_TOKENS.BASE_URL,
            },
        ];
        
        if(this.authService.loggedUser().id === 1) 
        {
            cmds_array.push({
                icon: 'keyboard_arrow_right',
                label: 'Imp. Giornalieri',
                cmds: [
                    {
                        label: 'Biglietterie',
                        route: `${TMT_TICKETS_ROUTER_TOKENS.BASE_URL}/${TMT_TICKETS_ROUTER_TOKENS.TICKET_OFFICE}`
                    },
                    {
                        label: 'Tipi linea',
                        route: `${TMT_TICKETS_ROUTER_TOKENS.BASE_URL}/${TMT_TICKETS_ROUTER_TOKENS.TICKET_TYPE}`
                    },
                    {
                        label: 'Linee',
                        route: `${TMT_TICKETS_ROUTER_TOKENS.BASE_URL}/${TMT_TICKETS_ROUTER_TOKENS.TICKET_LINE}`
                    },
                    {
                        label: 'Tratte',
                        route: `${TMT_TICKETS_ROUTER_TOKENS.BASE_URL}/${TMT_TICKETS_ROUTER_TOKENS.TICKET_MODEL}`
                    },
                ],
            });

            cmds_array.push({
                icon: 'attach_money',
                label: 'Registry',
                route: `${TMT_TICKETS_ROUTER_TOKENS.BASE_URL}/${TMT_TICKETS_ROUTER_TOKENS.TICKETS_REGISTRY}`
            });


            cmds_array.push({
                icon: 'attach_money',
                label: 'Statini',
                route: STATINI_ROUTER_TOKENS.BASE_URL,
            });
            cmds_array.push({
                icon: 'system_update_alt',
                label: 'Import',
                route: IMPORT_ROUTER_TOKENS.BASE_URL,
            });
            cmds_array.push({
                icon: 'manage_accounts',
                label: `Il tuo account`,
                route: `/${ACCOUNT_ROUTER_TOKENS.BASE_URL}`,
            });
        }

        cmds_array.push({
            icon: 'logout',
            label: 'Esci',
            route: `/${AUTH_ROUTER_TOKENS.LOGIN}`,
            fn: this.authService.logout()
        });

        this.cmds.set(cmds_array);
    }
}