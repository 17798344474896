import { InjectionToken } from '@angular/core';
import { DateTime } from 'luxon';

// It's an abstract class on purpose, as interfaces cannot be used as tokens for DI.
export abstract class ProfileConfig {
    abstract cf: boolean;
    abstract date_of_birth: boolean;
    abstract birth_place: boolean;
    abstract place_of_residence: boolean;
    abstract phone: boolean 
}

export const PROFILE_CONFIG = new InjectionToken<ProfileConfig>('profile.config');