export enum TMT_TICKETS_ROUTER_TOKENS {
    BASE_URL = 'tickets',   // feature-segment
    HOME = '',
    TICKET_OFFICE = 'ticket-office',
    TICKET_TYPE = 'ticket-type',
    TICKET_LINE = 'ticket-line',
    TICKET_MODEL = 'ticket-model',
    TICKETS_REGISTRY = 'tickets-registry',
    TICKET_REGISTRY = 'ticket',
    TICKET_HISTORY = 'history',
}
