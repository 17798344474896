<div class="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
    <div class="text-center">
        <p class="text-base font-semibold text-indigo-600">404</p>
        <h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Page not found</h1>
        <p class="mt-6 text-base leading-7 text-gray-600">Ci spiace ma questa pagina non esiste.</p>
        <div class="mt-10 flex items-center justify-center gap-x-6">
            <button type="button" mat-flat-button color="primary" [routerLink]="['/']">
                Torna a home
            </button>
        </div>
    </div>
</div>